.box {
  padding: 0 1.6rem;
  margin-bottom: 3.2rem;
}

.figure {
  margin-top: 1.2rem;
  border-radius: 12px;
  border: 1px solid #ddd;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.075);
  background-color: #fff;
}

.wrapper {
  border-bottom: 1px solid #ddd;
  padding: 1.6rem;
}

.addressBox {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
}

.addressDetails {
  line-height: 1.2;
}

.addressType {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-dark);
  margin-bottom: 0.4rem;
}

.addressBox ion-icon[name='map-outline'] {
  height: 3.4rem;
  width: 3.4rem;
  padding: 1.8rem;
  border-radius: 50%;

  background-color: var(--color-primary-light);
  color: var(--color-primary);
}

.addressBox ion-icon[name='chevron-forward-outline'] {
  height: 2.4rem;
  width: 2.4rem;
  color: var(--color-primary-dark);

  margin-left: auto;
  cursor: pointer;
}

/*  */

.instructions {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  font-size: 1.4rem;
}

.instructions input {
  font-family: inherit;
  color: var(--color-grey-dark);
  font-size: 1.4rem;
  font-weight: 600;

  border-radius: 9px;
  border: 1px solid #ddd;

  padding: 1.2rem;
}

.instructions input::placeholder {
  font-weight: 400;
  color: var(--color-grey-light-2);
}

/*  */

.leaveAtBox {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  padding: 1.4rem 1.8rem;
  font-size: 1.4rem;
  cursor: pointer;
}

.leaveAtBox ion-icon {
  width: 2rem;
  height: 2rem;
}

.checkbox {
  margin-left: auto;
}
