.box {
  margin: 0 1.6rem 3.2rem;
  padding: 1.6rem 0 1.6rem;

  border-radius: 12px;
  border: 1px solid #ddd;

  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.075);
  background-color: #fff;
}

.textBox {
  display: flex;
  align-items: center;
  gap: 2rem;

  font-size: 1.4rem;
  padding: 0 1.6rem;
  margin-bottom: 1.6rem;
}

.textBox ion-icon {
  width: 4.8rem !important;
  height: 4.8rem;
  aspect-ratio: 1 / 1;

  color: var(--color-primary);

  background-color: var(--color-primary-light);
  padding: 1rem 1.715rem;
  border-radius: 50%;
}

.text {
  margin-top: 1.2rem;
  line-height: 1.4;
}

/*  */

.tips {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  font-size: 1.4rem;
  color: var(--color-grey-dark);

  overflow-x: scroll;
  /* Enable smooth scrolling on touch devices */
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1rem;
}

/*  */

.tips::-webkit-scrollbar {
  height: 1rem;
  cursor: pointer;
}

.tips::-webkit-scrollbar-track {
  background-color: #eee;
  cursor: pointer;
  border-radius: 10px;
}

.tips::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #ccc;
  border-radius: 10px;
}

/*  */

.tip {
  padding: 0.6rem 1.4rem;
  background-color: #fff;

  border: 1px solid #ddd;
  border-radius: 100px;

  white-space: nowrap;
  cursor: pointer;
}

.selected {
  color: #fff;
  background-color: var(--color-primary);
}
