.box {
  padding: 1.6rem;
  margin-bottom: 1.2rem;
}

.delivery {
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 1.6rem 2.4rem;

  display: flex;
  align-items: center;
  gap: 3rem;

  font-size: 1.4rem;
}

.text {
  margin-bottom: 0.6rem;
}

.time {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-grey-dark);
  margin-bottom: 1rem;
}

.btnChange {
  font-weight: 600;
  color: var(--color-primary-dark);
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.delivery ion-icon {
  width: 3.6rem;
  height: 3.6rem;
  color: var(--color-primary-dark);

  padding: 1.6rem;
  background-color: var(--color-primary-light);
  border-radius: 50%;
}
