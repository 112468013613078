.tag {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.5rem 1.2rem;
  border-radius: 100px;
}

.required {
  color: #fff;
  background-color: var(--color-primary);
}

.optional {
  color: var(--color-grey-light);
  background-color: #ddd;
}
