.ctaBox {
  padding: 1.6rem 2.4rem;

  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  font-size: 1.6rem;
  color: var(--color-grey-dark);
  font-weight: 600;

  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #fff;
}

.textBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}

.including {
  color: var(--color-grey-light-2);
  font-size: 1.2rem;
  font-weight: normal;
}
