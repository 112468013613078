.btn {
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;

  color: var(--color-primary);
  background-color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  /* box-shadow: 0 1.8rem 3.6rem rgba(0, 0, 0, 0.1); */

  display: flex;
  justify-content: center;
  align-items: center;
}

.btn ion-icon {
  color: var(--color-primary-dark);
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.4rem;
}
