.categories {
  display: flex;
  gap: 3.2rem;
  background-color: #f7f7f7;
  overflow-y: scroll;
  padding: 1.2rem 1.6rem;

  /* temp */
  margin-top: 1rem;
  margin-bottom: 3.2rem;

  position: sticky;
  top: 4.8rem;
  z-index: 99;
}

.navItem:link,
.navItem:visited {
  color: var(--color-grey-dark);
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
}

.categories::-webkit-scrollbar {
  height: 0;
}
