.navigation {
  display: flex;
  justify-content: space-between;

  height: 4.8rem;
  padding: 0 2rem;
  border-bottom: 2px solid #eee;
  background-color: #fff;

  position: sticky;
  top: 0;
  z-index: 100;
}

.group {
  display: flex;
  gap: 1.6rem;
}
