.btnOpen {
  position: absolute;
  bottom: 0.6rem;
  right: 0.4rem;
}

.btn {
  color: var(--color-primary);
  background-color: #fff;
  border: none;
  border-radius: 50%;
  /* box-shadow: 0 1.8rem 3.6rem rgba(0, 0, 0, 0.1); */

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn ion-icon {
  color: var(--color-primary-dark);
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.4rem;
}

.btnGroup {
  position: absolute;
  bottom: 0.6rem;
  right: 0.4rem;
  width: 85%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;
  padding: 0 0.4rem;
  border-radius: 20px;
}

.btnGroup .btn {
  background-color: transparent;
  box-shadow: none;
}

.counter {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-grey-dark);
}
