.btn ion-icon {
  padding: 0.6rem;
  height: 3rem;
  width: 3rem;
  border: none;
  border-radius: 50%;

  color: var(--color-primary-dark);
  background-color: rgba(230, 230, 230, 0.9);
  cursor: pointer;

  position: fixed;
  top: 4.8rem;
  transform: translateX(1rem);
  /* left: 2.4rem; */
  z-index: 100;
}
