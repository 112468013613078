.offers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.8rem;
  margin-top: 1.2rem;
}

/*  */

.offerBox {
  padding: 1.2rem 1.6rem;
  border-radius: 8px;
  line-height: 1.3;
}

.title {
  display: flex;
  gap: 0.8rem;
}

.title span {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--color-grey-dark);
  margin-bottom: 0.4rem;
}

.title ion-icon {
  width: 1.2rem;
  height: 1.2rem;
}
