.header {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  padding: 1.6rem;

  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #fff;
}

.text {
  margin-top: 0.4rem;
  font-size: 1.4rem;
}

.btnBack {
  cursor: pointer;
  border: none;
  background-color: #fff;
}

.btnBack ion-icon {
  width: 2.4rem;
  height: 2.4rem;
  color: var(--color-primary-dark);
  cursor: pointer;
}

.header.colorGrey {
  background-color: #f7f7f7;
}
.header.colorGrey .btnBack {
  background-color: #f7f7f7;
}
