.box {
  background-color: #f7f7f7;
  padding: 2.4rem 1.6rem;
  min-height: calc(100vh - 6.8rem);
}

.orders {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  margin-top: 1.2rem;
  margin-bottom: 3.2rem;
}

.header {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1.6rem;
}

.summary {
  display: grid;
  grid-template-columns: 1fr min-content;
  font-size: 1.4rem;
}

.name,
.price {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-grey-dark);
}

.delivered {
  font-size: 1.2rem;
}

.price {
  white-space: nowrap;
}

.items {
  grid-column: span 2;
}

.order {
  padding: 1.6rem;
  border-radius: 9px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.img {
  width: 100%;
  border-radius: 9px;
}
