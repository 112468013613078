.btn {
  height: 5rem;
  width: 100%;
  padding: 0 1.6rem;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.75px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  border: none;
  border-radius: 9px;
  background-color: var(--color-primary);
}

.block {
  display: block;
}

.fixed {
  position: sticky;
  bottom: 0;
}
