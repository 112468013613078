.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;

  padding: 2.4rem 1.6rem;
  font-size: 1.4rem;
}
.detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.free {
  display: inline-block;
  color: var(--color-primary);
  background-color: var(--color-primary-light);
  font-weight: 600;

  padding: 0.4rem 1.2rem;
  border-radius: 100px;
}
