.box {
  padding: 0 1.6rem;
  margin-bottom: 3.2rem;
}

.deliveryOptions {
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.075);
  border-radius: 12px;

  list-style: none;
  font-size: 1.4rem;
  margin-top: 1.2rem;
  border: 1px solid #ddd;
  background-color: #fff;
}

.deliveryOption {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  padding: 2rem 1.6rem;
  border-radius: 12px;
  cursor: pointer;
}

.deliveryType {
  color: var(--color-grey-dark);
  font-size: 1.6rem;
  font-weight: 600;
}

.deliveryPrice {
  padding: 0.4rem 0.8rem;
  border-radius: 100px;
  border: 1px solid #ddd;

  margin-left: auto;
}

/*  */

.selected {
  box-shadow: inset 0 0 0 3px var(--color-primary-dark);
}
