.recommendations {
  padding: 1.6rem 1.6rem;
  margin-top: 2rem;

  border-top: 6px solid #ddd;
  border-bottom: 6px solid #ddd;
}

.text {
  font-size: 1.4rem;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
}

/*  */

.meals {
  display: flex;
  align-items: start;
  gap: 0.8rem;

  overflow-x: auto;
  padding-bottom: 1.2rem;
}

.meals::-webkit-scrollbar {
  height: 1.2rem;
  cursor: pointer;
}

.meals::-webkit-scrollbar-track {
  background-color: #eee;
  cursor: pointer;
  border-radius: 10px;
}

.meals::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #ccc;
  border-radius: 10px;
}

.meal {
  border-radius: 9px;
  font-size: 1.4rem;
  position: relative;
}

.imgBox {
  position: relative;
  width: 12.8rem;
  height: 12.8rem;
  border-radius: 9px;
  margin-bottom: 1.2rem;
  overflow: hidden;
}

.img {
  width: 100%;
}

.price {
  margin-bottom: 0.6rem;
  font-weight: 600;
}

.name {
  margin-top: 0.8rem;
  font-size: 1.4rem;
  line-height: 1.2;
}
